import { useState } from 'react';

import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import {
  OrganizerMultiSelect,
  type OrganizerSelectOption,
} from '../../../Organization/OrganizerSelect';
import { useUser } from '../../../UserContext';

interface AddMembersToGroupModalProps {
  group: {
    id: string;
    name: string;
  };
  onClose: () => void;
  onConfirm: (emails: string[]) => void;
}

export function AddMembersToGroupModal({
  group,
  onClose,
  onConfirm,
}: AddMembersToGroupModalProps) {
  const [emailOptions, setEmailOptions] = useState<OrganizerSelectOption[]>([]);
  const user = useUser();
  const orgId = user.organizer?.orgId;

  const handleAssign = () => {
    const emails = emailOptions.map((opt) => {
      if (opt.kind === 'organizer') {
        return opt.organizer.email;
      } else {
        return opt.value;
      }
    });

    if (emails.length === 0) return;
    onConfirm(emails);
  };

  const isDisabled = emailOptions.length === 0;

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[600px] h-auto'
      innerClassName='bg-main-layer flex flex-col'
      onClose={onClose}
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        {/* Header */}
        <div className='text-2xl font-bold text-center'>
          Add Members to {group.name}
        </div>

        {/* Email Input */}
        <div className='flex flex-col gap-2'>
          <label className='text-sms font-medium text-icon-gray'>
            Enter email addresses
          </label>
          <OrganizerMultiSelect
            orgId={orgId ?? ''}
            options={emailOptions}
            onChange={setEmailOptions}
            creatable
            placeholder='Enter one or more email addresses'
            className='w-full'
            scrollable={{ maxHeight: 200 }}
            badgeColor='#4CE3B3' /* Luna primary color */
            badgeTextColor='#000000' /* Black text for better readability */
          />
        </div>

        {/* Help Text */}
        <p className='text-sms text-icon-gray text-center'>
          Added members will receive an email invitation to join the group and
          will see any assigned courses and stacks.
        </p>

        {/* Action Buttons */}
        <div className='flex justify-center gap-4 mt-4'>
          <button
            type='button'
            onClick={onClose}
            className='btn btn-secondary px-6 py-2'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleAssign}
            disabled={isDisabled}
            className={`btn btn-luna-primary px-6 py-2 ${
              isDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Add Members
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
